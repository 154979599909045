import BezierEasing from 'bezier-easing';

import type { Composition } from '../compositions';
import type { SubCompositionLayer, VideoDescriptorLayer } from '../layers';
import { clamp } from '@libs/util-ts';
import { latest as LatestVDETypes } from '@libs/waymark-video/video-descriptor-types';

import type { VideoDescriptorTimeline } from './VideoDescriptorTimeline';

abstract class BaseCompositionTimeline {
  composition: Composition;
  inPoint: number;
  outPoint: number;
  videoDescriptorTimeline: VideoDescriptorTimeline;

  parentCompositionTimeline: SubCompositionTimeline | RootCompositionTimeline | null;

  get frameDuration() {
    return this.outPoint - this.inPoint;
  }

  static getEasingFunctionFromProjectManifestKeyframe(
    keyframe:
      | LatestVDETypes.ValueKeyframe
      | LatestVDETypes.MultiDimensionalKeyframe
      | LatestVDETypes.ValueHoldKeyframe
      | LatestVDETypes.MultiDimensionalHoldKeyframe,
  ): BezierEasing.EasingFunction {
    let inX = 0;
    let inY = 0;
    let outX = 1;
    let outY = 1;

    if ('i' in keyframe && keyframe.i) {
      // If bezier values are provided as an array, just use the first value
      inX = Math.max(Math.min(Array.isArray(keyframe.i.x) ? keyframe.i.x[0] : keyframe.i.x, 1), 0);
      inY = Math.max(Math.min(Array.isArray(keyframe.i.y) ? keyframe.i.y[0] : keyframe.i.y, 1), 0);
    }

    if ('o' in keyframe && keyframe.o) {
      outX = Math.max(Math.min(Array.isArray(keyframe.o.x) ? keyframe.o.x[0] : keyframe.o.x, 1), 0);
      outY = Math.max(Math.min(Array.isArray(keyframe.o.y) ? keyframe.o.y[0] : keyframe.o.y, 1), 0);
    }

    return BezierEasing(inX, inY, outX, outY);
  }

  static getProjectManifestValueAtLocalFrame(
    value:
      | LatestVDETypes.Value
      | LatestVDETypes.ValueKeyframed
      | LatestVDETypes.MultiDimensionalValue
      | LatestVDETypes.MultiDimensionalValueKeyframed,
    frame: number,
  ) {
    if (value.a === 0) {
      return value.k;
    }

    const keyframes = value.k;

    const keyframesStartTime = keyframes[0].t;
    const keyframesEndTime = keyframes[keyframes.length - 1].t;

    // Clamp the frame to the keyframes' range; if the frame falls before the first keyframe, we'll return that
    // first keyframe's start value, and if it falls after the last keyframe, we'll return that last keyframe's end value
    const queryFrame = clamp(frame, keyframesStartTime, keyframesEndTime);

    for (let i = 0; i < keyframes.length; i += 1) {
      const currentKeyframe = keyframes[i];
      if (!('s' in currentKeyframe)) {
        // This is a "last time" keyframe; if we somehow got here,
        // we weren't able to get a value from the keyframes
        break;
      }

      const nextKeyframe = keyframes[i + 1];
      if (!nextKeyframe) {
        break;
      }

      const tweenStartFrame = currentKeyframe.t;
      const tweenEndFrame = nextKeyframe.t;

      // If the next keyframe is the final "last time keyframe" with no values, we should
      // extract a value from the current keyframe and return it regardless of whether the query frame
      // falls within the tween range
      const isNextKeyframeFinal = !('s' in nextKeyframe);

      if (queryFrame >= tweenStartFrame && (queryFrame < tweenEndFrame || isNextKeyframeFinal)) {
        const startValue = currentKeyframe.s;
        if (startValue === true) {
          // A start value of true indicates no value. Just return null.
          return null;
        }

        const endValue = 'e' in currentKeyframe ? currentKeyframe.e : null;
        if (endValue === null) {
          // No end value means this is a hold frame, so just return the start value
          return startValue;
        }

        const easeFunction =
          BaseCompositionTimeline.getEasingFunctionFromProjectManifestKeyframe(currentKeyframe);

        const tweenDuration = tweenEndFrame - tweenStartFrame;
        const progressRatio = easeFunction(
          clamp((queryFrame - tweenStartFrame) / tweenDuration, 0, 1),
        );

        if (typeof startValue === 'number' && typeof endValue === 'number') {
          return startValue + progressRatio * (endValue - startValue);
        } else if (Array.isArray(startValue) && Array.isArray(endValue)) {
          return startValue.map((startValue, index) => {
            return startValue + progressRatio * (endValue[index] - startValue);
          });
        } else {
          console.error('Unexpected type mismatch between keyframe start and end values.', value);
          return null;
        }
      }
    }

    return null;
  }

  constructor(
    composition: Composition,
    inPoint: number,
    outPoint: number,
    videoDescriptorTimeline: VideoDescriptorTimeline,
    parentCompositionTimelineInstance: SubCompositionTimeline | RootCompositionTimeline | null,
  ) {
    this.composition = composition;
    this.inPoint = inPoint;
    this.outPoint = outPoint;
    this.videoDescriptorTimeline = videoDescriptorTimeline;
    this.parentCompositionTimeline = parentCompositionTimelineInstance;
  }

  getIsCompositionActiveAtLocalFrame(localFrame: number): boolean {
    // Keep in mind that localFrame is relative to this composition's in/out points, so
    // the starting point would be 0
    return localFrame >= 0 && localFrame < this.frameDuration;
  }

  getIsCompositionActiveAtGlobalFrame(globalFrame: number): boolean {
    const localFrame = this.convertGlobalFrameToLocalFrame(globalFrame);
    return this.getIsCompositionActiveAtLocalFrame(localFrame);
  }

  getCompositionGlobalInPointFrame(): number {
    const parentInPointFrame =
      this.parentCompositionTimeline?.getCompositionGlobalInPointFrame() ?? 0;
    return (
      parentInPointFrame +
      // Clamp the inPoint to 0 if it's negative; a layer can't be functionally active before its parent composition is active
      Math.max(this.inPoint, 0)
    );
  }

  getCompositionGlobalOutPointFrame(): number {
    if (this.parentCompositionTimeline) {
      const parentInPointFrame = this.parentCompositionTimeline.getCompositionGlobalInPointFrame();
      const parentOutPointFrame =
        this.parentCompositionTimeline?.getCompositionGlobalOutPointFrame();
      // Clamp the outPoint to the parent composition's out point if it's greater;
      // a layer can't be functionally active after its parent composition is inactive
      return Math.min(parentInPointFrame + this.outPoint, parentOutPointFrame);
    }

    return this.outPoint;
  }

  /**
   * Takes a frame number in the root-level timeline's global space and converts it to a frame number in this timeline's local space (relative to this composition's in/out points)
   */
  convertGlobalFrameToLocalFrame(globalFrame: number): number {
    const compositionInPoint = this.getCompositionGlobalInPointFrame();
    return globalFrame - compositionInPoint;
  }

  /**
   * Takes a frame number in this timeline's local space (relative to this comp's in/out points) and converts it to a frame number in the root-level timeline's global space.
   */
  convertLocalFrameToGlobalFrame(localFrame: number): number {
    const compositionInPoint = this.getCompositionGlobalInPointFrame();
    return compositionInPoint + localFrame;
  }

  getGlobalInOutPointFramesForLayer(layer: VideoDescriptorLayer): {
    inPoint: number;
    outPoint: number;
  } {
    const compositionStartFrame = this.getCompositionGlobalInPointFrame();
    const compositionEndFrame = this.getCompositionGlobalOutPointFrame();

    const inPoint = compositionStartFrame + layer.rawLayerData.ip;
    const outPoint = Math.min(compositionStartFrame + layer.rawLayerData.op, compositionEndFrame);
    return { inPoint, outPoint };
  }

  getOpacityAdjustedInOutPointFramesForLayer(layer: VideoDescriptorLayer): {
    adjustedInOutPoints: { inPoint: number; outPoint: number };
    actualInOutPoints: { inPoint: number; outPoint: number };
  } | null {
    const { inPoint: actualInPointFrame, outPoint: actualOutPointFrame } =
      this.getGlobalInOutPointFramesForLayer(layer);

    let adjustedInPointFrame = actualInPointFrame;
    let adjustedOutPointFrame = actualOutPointFrame;

    const opacityValueObject = 'o' in layer.rawLayerData.ks ? layer.rawLayerData.ks.o : null;
    if (opacityValueObject?.a === 0 && opacityValueObject.k === 0) {
      // If the opacity is 0 and doesn't change, the layer is never visible! We'll return null to signal that.
      return null;
    } else if (opacityValueObject?.a === 1) {
      const keyframes = opacityValueObject.k;

      const keyframeCount = keyframes.length;

      const getKeyframeValue = (value: number | number[]): number => {
        return Array.isArray(value) ? value[0] : value;
      };

      // Walk forwards through the keyframes to find the first frame where the opacity is visible
      for (let i = 0; i < keyframeCount; i += 1) {
        const keyframe = keyframes[i];
        if (!('s' in keyframe) || typeof keyframe.s === 'boolean') {
          continue;
        }

        const tweenStartValue = getKeyframeValue(keyframe.s);

        if (tweenStartValue > 0) {
          // The first keyframe is visible, so the in point doesn't need adjustment
          break;
        } else if ('e' in keyframe) {
          const tweenEndValue = getKeyframeValue(keyframe.e);

          if (tweenEndValue > 0) {
            // If we have a tween end value that's visible, we know the layer can be considered visible at the end of that tween
            const tweenEndTime = this.convertLocalFrameToGlobalFrame(
              keyframes[i + 1]?.t ?? keyframe.t,
            );
            adjustedInPointFrame = Math.max(actualInPointFrame, tweenEndTime);
            break;
          }
        }
      }

      // Walk backwards through the keyframes to find the last frame where the opacity is visible
      for (let i = keyframeCount - 1; i >= 0; i -= 1) {
        const keyframe = keyframes[i];
        if (!('s' in keyframe) || typeof keyframe.s === 'boolean') {
          continue;
        }

        if ('e' in keyframe) {
          const tweenEndValue = getKeyframeValue(keyframe.e);
          if (tweenEndValue > 0) {
            // The last keyframe is visible, so the out point doesn't need adjustment
            break;
          } else {
            const tweenStartValue = getKeyframeValue(keyframe.s);
            if (tweenStartValue > 0) {
              const tweenStartTime = this.convertLocalFrameToGlobalFrame(keyframe.t);
              adjustedOutPointFrame = Math.min(actualOutPointFrame, tweenStartTime);
              break;
            }
          }
        } else {
          // If we found a hold keyframe with a visible value, we know the layer is visible until its out point and can stop searching
          const tweenStartValue = getKeyframeValue(keyframe.s);
          if (tweenStartValue > 0) {
            break;
          }
        }
      }
    }

    if (this instanceof SubCompositionTimeline && this.parentCompositionTimeline) {
      const parentAdjustedInOutPoints =
        this.parentCompositionTimeline.getOpacityAdjustedInOutPointFramesForLayer(
          this.compositionInstanceLayer,
        );
      if (!parentAdjustedInOutPoints) {
        return null;
      }

      adjustedInPointFrame = clamp(
        adjustedInPointFrame,
        parentAdjustedInOutPoints.adjustedInOutPoints.inPoint,
        parentAdjustedInOutPoints.adjustedInOutPoints.outPoint,
      );
      adjustedOutPointFrame = clamp(
        adjustedOutPointFrame,
        parentAdjustedInOutPoints.adjustedInOutPoints.inPoint,
        parentAdjustedInOutPoints.adjustedInOutPoints.outPoint,
      );
    }

    if (adjustedInPointFrame >= adjustedOutPointFrame) {
      // If the adjusted in/out points have been shifted so there is no duration where the layer is visible, return null to reflect that the layer is never visible!
      return null;
    }

    const results = {
      adjustedInOutPoints: {
        inPoint: adjustedInPointFrame,
        outPoint: adjustedOutPointFrame,
      },
      actualInOutPoints: {
        inPoint: actualInPointFrame,
        outPoint: actualOutPointFrame,
      },
    };

    return results;
  }

  /**
   * Determine whether a given layer in the composition is active at a given frame in the global timeline scope,
   * meaning the layer is not hidden and the frame falls
   */
  getIsLayerActiveAtGlobalFrame(layer: VideoDescriptorLayer, globalFrame: number): boolean {
    if (layer.parentComposition !== this.composition || layer.getIsHidden()) {
      return false;
    }

    const { inPoint, outPoint } = this.getGlobalInOutPointFramesForLayer(layer);

    return globalFrame >= inPoint && globalFrame < outPoint;
  }

  /**
   * Whether or not a layer is active at a given frame in the local timeline scope.
   */
  getIsLayerActiveAtLocalFrame(layer: VideoDescriptorLayer, localFrame: number): boolean {
    // Convert the local frame to a global frame and check if the layer is active at that frame
    // This is more accurate than a pure local frame check because it accounts for cases where a
    // the layer's in/out points may fall outside of a parent composition's in/out points
    const globalFrame = this.convertLocalFrameToGlobalFrame(localFrame);
    return this.getIsLayerActiveAtGlobalFrame(layer, globalFrame);
  }

  /**
   * Gather all layers that are active at a given frame in the local timeline scope.
   */
  getActiveLayersAtLocalFrame(localFrame: number): VideoDescriptorLayer[] {
    // If the frame is outside of the composition, no layers are active.
    if (!this.getIsCompositionActiveAtLocalFrame(localFrame)) {
      return [];
    }

    return this.composition.layers.filter((layer) =>
      this.getIsLayerActiveAtLocalFrame(layer, localFrame),
    );
  }

  getActiveLayersAtGlobalFrame(globalFrame: number): VideoDescriptorLayer[] {
    const localFrame = this.convertGlobalFrameToLocalFrame(globalFrame);
    return this.getActiveLayersAtLocalFrame(localFrame);
  }

  /**
   * Whether or not a layer is visible at a given frame in the local timeline scope.
   * We will define visibility as:
   * 1. Active at the frame
   * 2. Opacity is greater than 0 at the frame
   *
   * This does not account for layers which may be blocked behind other layers.
   */
  getIsLayerVisibleAtGlobalFrame(layer: VideoDescriptorLayer, globalFrame: number): boolean {
    if (!this.getIsLayerActiveAtGlobalFrame(layer, globalFrame)) {
      return false;
    }

    const opacity = this.getGlobalChildLayerOpacityAtFrame(layer, globalFrame);
    return opacity !== null && opacity > 0;
  }

  /**
   * Gather all layers that are visible at a given frame in the local timeline scope.
   */
  getVisibleLayersAtGlobalFrame(globalFrame: number): VideoDescriptorLayer[] {
    // If the frame is outside of the composition, no layers are visible.
    // Keep in mind that localFrame is relative to this composition's in/out points, so
    // the starting point would be 0
    if (!this.getIsCompositionActiveAtGlobalFrame(globalFrame)) {
      return [];
    }

    return this.composition.layers.filter((layer) =>
      this.getIsLayerVisibleAtGlobalFrame(layer, globalFrame),
    );
  }

  /**
   * Gets the "origin point" of a layer; that is, the point which any children of this layer will be positioned relative to.
   */
  getLocalChildLayerOriginPointAtFrame(
    layer: VideoDescriptorLayer,
    localFrame: number,
  ): { x: number; y: number; z: number } | null {
    // Position and anchor point coordinates are both relative to the composition, but children of this layer will be positioned
    // relative to its origin point, which is the position relative to the anchor. Confusing? Yes, a little.
    const layerPosition = this.getLocalChildLayerPositionAtFrame(layer, localFrame);
    const layerAnchorPoint = this.getLocalChildLayerAnchorPositionAtFrame(layer, localFrame);

    if (!layerPosition) {
      return null;
    }

    return {
      x: layerPosition.x - (layerAnchorPoint?.x ?? 0),
      y: layerPosition.y - (layerAnchorPoint?.y ?? 0),
      z: layerPosition.z - (layerAnchorPoint?.z ?? 0),
    };
  }

  /**
   * Gets the global "origin point" of a layer; that is, the point which any children of this layer will be positioned relative to,
   * in the global space of the root composition timeline.
   */
  getGlobalChildLayerOriginPointAtFrame(
    layer: VideoDescriptorLayer,
    globalFrame: number,
  ): { x: number; y: number; z: number } | null {
    const localFrame = this.convertGlobalFrameToLocalFrame(globalFrame);
    const originPoint = this.getLocalChildLayerOriginPointAtFrame(layer, localFrame);
    if (!originPoint) {
      return null;
    }

    if (this instanceof SubCompositionTimeline) {
      const parentCompositionOriginPoint =
        this.parentCompositionTimeline?.getGlobalChildLayerOriginPointAtFrame(
          this.compositionInstanceLayer,
          globalFrame,
        );
      if (parentCompositionOriginPoint) {
        originPoint.x += parentCompositionOriginPoint.x;
        originPoint.y += parentCompositionOriginPoint.y;
        originPoint.z += parentCompositionOriginPoint.z;
      }
    }

    return originPoint;
  }

  /**
   * Gets the local anchor position of a layer relative to this composition at a given frame within this composition instance.
   */
  getLocalChildLayerAnchorPositionAtFrame(
    layer: VideoDescriptorLayer,
    localFrame: number,
  ): { x: number; y: number; z: number } | null {
    if (layer.parentComposition !== this.composition) {
      console.warn('Layer does not belong to this composition');
      return null;
    }

    const { rawLayerData } = layer;

    const { ip: layerLocalInPointFrame, op: layerLocalOutPointFrame } = rawLayerData;

    if (localFrame < layerLocalInPointFrame || localFrame >= layerLocalOutPointFrame) {
      // Layer is not active at this frame
      return null;
    }

    if (!rawLayerData.ks.a) {
      return null;
    }

    const anchorPositionTuple = BaseCompositionTimeline.getProjectManifestValueAtLocalFrame(
      rawLayerData.ks.a,
      localFrame,
    );

    if (!Array.isArray(anchorPositionTuple)) {
      console.warn(
        `WARNING: Anchor position values ${JSON.stringify(
          anchorPositionTuple,
        )} at local timeline frame ${localFrame} are not a valid tuple array for layer ${layer.toString()}`,
        rawLayerData.ks.a,
      );
      return null;
    }

    const [x = 0, y = 0, z = 0] = anchorPositionTuple;
    if (typeof x !== 'number' || typeof y !== 'number' || typeof z !== 'number') {
      console.warn(
        `WARNING: Anchor position values { x: ${x}, y: ${y}, z: ${z} } at local timeline frame ${localFrame} are not a number for layer ${layer.toString()}`,
        rawLayerData.ks.a,
      );
      return null;
    } else {
      return {
        x,
        y,
        z,
      };
    }
  }

  /**
   * Gets the local position of a layer relative to this composition at a given frame within this composition instance.
   * NOTE: this position may not 100% match where the layer is visually positioned in the case that a layer is rotated around an external
   * anchor point, but it should be accurate for most cases. If/when the time comes that we need a higher level of accuracy, we will need
   * to start using a transform matrix.
   */
  getLocalChildLayerPositionAtFrame(
    layer: VideoDescriptorLayer,
    localFrame: number,
  ): { x: number; y: number; z: number } | null {
    if (layer.parentComposition !== this.composition) {
      console.warn('Layer does not belong to this composition');
      return null;
    }

    const { rawLayerData } = layer;

    const { ip: layerLocalInPointFrame, op: layerLocalOutPointFrame } = rawLayerData;

    if (localFrame < layerLocalInPointFrame || localFrame >= layerLocalOutPointFrame) {
      // Layer is not active at this frame
      return null;
    }

    const position = {
      x: 0,
      y: 0,
      z: 0,
    };

    const parentLayer = layer.getParentLayer();
    if (parentLayer) {
      const parentOriginPoint = this.getLocalChildLayerOriginPointAtFrame(parentLayer, localFrame);

      if (parentOriginPoint) {
        position.x += parentOriginPoint.x;
        position.y += parentOriginPoint.y;
        position.z += parentOriginPoint.z;
      }
    }

    if ('p' in rawLayerData.ks && rawLayerData.ks.p) {
      if ('s' in rawLayerData.ks.p) {
        // If the dimensions are separated, we need to get the x, y, and z values separately
        let x = BaseCompositionTimeline.getProjectManifestValueAtLocalFrame(
          rawLayerData.ks.p.x,
          localFrame,
        );
        let y = BaseCompositionTimeline.getProjectManifestValueAtLocalFrame(
          rawLayerData.ks.p.y,
          localFrame,
        );
        let z = rawLayerData.ks.p.z
          ? BaseCompositionTimeline.getProjectManifestValueAtLocalFrame(
              rawLayerData.ks.p.z,
              localFrame,
            )
          : 0;

        // In some cases for some reason, the x, y, and z values may potentially be a tuple with a single value,
        // so we need additional handling to unwrap those values just in case
        if (Array.isArray(x) && x.length === 1) {
          x = x[0];
        }
        if (Array.isArray(y) && y.length === 1) {
          y = y[0];
        }
        if (Array.isArray(z) && z.length === 1) {
          z = z[0];
        }

        if (typeof x !== 'number' || typeof y !== 'number' || typeof z !== 'number') {
          // We shouldn't ever reach this state where the values are misconfigured like this, but we'll handle it just in case for TS
          console.warn(
            `WARNING: Position values { x: ${x}, y: ${y}, z: ${z} } at local timeline frame ${localFrame} are not all numbers. ${layer.toString()}`,
            rawLayerData.ks.p,
          );
          return null;
        }

        position.x += x;
        position.y += y;
        position.z += z;
      } else {
        const positionTuple = BaseCompositionTimeline.getProjectManifestValueAtLocalFrame(
          rawLayerData.ks.p,
          localFrame,
        );

        if (!Array.isArray(positionTuple)) {
          console.warn(
            `WARNING: Position values ${JSON.stringify(
              positionTuple,
            )} at local timeline frame ${localFrame} are not a valid tuple array for layer ${layer.toString()}`,
            rawLayerData.ks.p,
          );
          return null;
        }

        const [x = 0, y = 0, z = 0] = positionTuple;

        if (typeof x !== 'number' || typeof y !== 'number' || typeof z !== 'number') {
          console.warn(
            `WARNING: Position values { x: ${x}, y: ${y}, z: ${z} } at local timeline frame ${localFrame} are not a number for layer ${layer.toString()}`,
            rawLayerData.ks.p,
          );
          return null;
        }

        position.x += x;
        position.y += y;
        position.z += z;
      }
    }

    return position;
  }

  getGlobalChildLayerPositionAtFrame(
    layer: VideoDescriptorLayer,
    globalFrame: number,
  ): { x: number; y: number; z: number } | null {
    const localFrame = this.convertGlobalFrameToLocalFrame(globalFrame);
    const position = this.getLocalChildLayerPositionAtFrame(layer, localFrame);
    if (!position) {
      return null;
    }

    if (this instanceof SubCompositionTimeline) {
      const parentOriginPoint =
        this.parentCompositionTimeline?.getGlobalChildLayerOriginPointAtFrame(
          this.compositionInstanceLayer,
          globalFrame,
        );
      if (parentOriginPoint) {
        position.x += parentOriginPoint.x;
        position.y += parentOriginPoint.y;
        position.z += parentOriginPoint.z;
      }
    }

    return position;
  }

  /**
   * Gets the local opacity of a layer at a given frame within this composition instance.
   * Note that opacity is stored in the project manifest on a scale of 0-100, but
   * we'll convert it to a 0-1 scale for ease of use.
   */
  getLocalChildLayerOpacityAtFrame(layer: VideoDescriptorLayer, localFrame: number): number | null {
    if (layer.parentComposition !== this.composition) {
      console.warn('Layer does not belong to this composition');
      return null;
    }

    const { rawLayerData } = layer;

    if (!this.getIsLayerActiveAtLocalFrame(layer, localFrame)) {
      return null;
    }

    if (!('o' in rawLayerData.ks) || !rawLayerData.ks.o) {
      // Default to 1 if no opacity keyframes are set
      return 1;
    }

    let opacity = BaseCompositionTimeline.getProjectManifestValueAtLocalFrame(
      rawLayerData.ks.o,
      localFrame,
    );

    if (Array.isArray(opacity) && opacity.length === 1) {
      // Handling just in case opacity is set as a one-value tuple since those have a habit of showing up unexpectedly
      opacity = opacity[0];
    } else if (typeof opacity !== 'number') {
      console.warn(
        `WARNING: Opacity value ${opacity} at local timeline frame ${localFrame} is not a number for layer ${layer.toString()}`,
        rawLayerData.ks.o,
      );
      return null;
    }

    // Opacity in the project manifest is from 0-100, so we'll convert it to a 0-1 scale to just be nicer
    // to work with.
    // Also note that we're intentionally not factoring in the opacity of the layer's parent layer if it has one;
    // this is because for some reason, children don't inherit opacity from parented layers
    return opacity / 100;
  }

  /**
   * Gets the global opacity of a layer at a given frame, factoring in the opacity of parent composition layers.
   * Note that opacity is stored in the project manifest on a scale of 0-100, but
   * we'll convert it to a 0-1 scale for ease of use.
   */
  getGlobalChildLayerOpacityAtFrame(
    layer: VideoDescriptorLayer,
    globalFrame: number,
  ): number | null {
    const localFrame = this.convertGlobalFrameToLocalFrame(globalFrame);
    let opacity = this.getLocalChildLayerOpacityAtFrame(layer, localFrame);

    if (opacity === null) {
      return null;
    }

    if (this instanceof SubCompositionTimeline) {
      const parentOpacity = this.parentCompositionTimeline?.getGlobalChildLayerOpacityAtFrame(
        this.compositionInstanceLayer,
        globalFrame,
      );
      if (parentOpacity === null) {
        return null;
      }
      opacity *= parentOpacity;
    }

    return opacity;
  }
}

export class SubCompositionTimeline extends BaseCompositionTimeline {
  compositionInstanceLayer: SubCompositionLayer;
  parentCompositionTimeline: SubCompositionTimeline | RootCompositionTimeline;

  constructor(
    compositionInstancelayer: SubCompositionLayer,
    parentCompositionTimeline: SubCompositionTimeline | RootCompositionTimeline,
  ) {
    const composition = compositionInstancelayer.composition;
    const { ip, op } = compositionInstancelayer.rawLayerData;
    const { videoDescriptorTimeline } = parentCompositionTimeline;
    super(composition, ip, op, videoDescriptorTimeline, parentCompositionTimeline);

    this.compositionInstanceLayer = compositionInstancelayer;
    this.parentCompositionTimeline = parentCompositionTimeline;
  }
}

export class RootCompositionTimeline extends BaseCompositionTimeline {
  constructor(rootComposition: Composition, videoDescriptorTimeline: VideoDescriptorTimeline) {
    const videoDescriptor = videoDescriptorTimeline.videoDescriptor;
    const { ip, op } = videoDescriptor.rawData.projectManifest;
    super(rootComposition, ip, op, videoDescriptorTimeline, null);
  }
}
