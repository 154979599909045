import {
  WaymarkVideoLayer as PMWaymarkVideoLayer,
  LayerType,
  AssetModificationFits,
  ContentCropping,
  FitFillAlignment,
  ValueKeyframed,
  ContentZoom,
} from '@libs/waymark-video/video-descriptor-types';

import { ImageAsset, FootageAsset } from '../assets';
import { FootageAssetQuality } from '../assets/locations/FootageAssetLocation';
import { toPrecision } from '../utils/math';

import { BaseLayer } from './BaseLayer';
import { ImageLayer } from './ImageLayer';

export interface FootageTrimData {
  /**
   * The start time of the trimmed content in seconds
   */
  trimStartTime: number;
  /**
   * The duration of the trimmed content in seconds
   * May be undefined if the layer does not have a trim modification set.
   * Should be defaulted to the display duration of the layer.
   */
  trimDuration: number;
}

export class FootageLayer extends BaseLayer<
  PMWaymarkVideoLayer,
  {
    'change:asset': FootageAsset;
    'change:contentModifications': undefined;
    'change:muted': boolean;
    'change:masterVolume': number;
    'change:visibility': boolean;
    'change:fillEffectColor': undefined;
    removed: undefined;
  }
> {
  /**
   * Creates a new FootageLayer from an ImageLayer.
   *
   * @param imageLayer - The ImageLayer base the new FootageLayer on
   * @param initialFootageAsset - The asset to use for the new FootageLayer
   */
  static fromImageLayer(imageLayer: ImageLayer, initialFootageAsset: FootageAsset): FootageLayer {
    const { rawLayerData: rawImageLayerData, videoDescriptor } = imageLayer;

    const footageAssetID = initialFootageAsset.getID();

    if (
      !(
        'h' in rawImageLayerData &&
        rawImageLayerData.h !== undefined &&
        'w' in rawImageLayerData &&
        rawImageLayerData.w !== undefined
      )
    ) {
      throw new Error('Cannot convert an ImageLayer without dimensions to a FootageLayer');
    }

    // Ensure the new footage asset is added
    videoDescriptor.addAsset(initialFootageAsset);

    // Update the layer data in-place to become a video layer
    const rawLayerData: PMWaymarkVideoLayer = {
      ...rawImageLayerData,
      contentZoom: FootageLayer.DEFAULT_ZOOM,
      // NOTE: The explicit 'h' and 'w' are due to TS not inferring that 'h' and 'w' are
      // guaranteed to be present when simply spreading rawImageLayerData. There may be a better way to do this.
      h: rawImageLayerData.h,
      w: rawImageLayerData.w,
      ty: LayerType.WaymarkVideo,
      refId: footageAssetID,
    };

    // Ensure the new footage asset is added
    videoDescriptor.addAsset(initialFootageAsset);

    const footageLayer = new FootageLayer(rawLayerData, imageLayer.parentComposition);

    /**
     * We noticed on https://waymark.com/videos/zebra_4x5_v2.default/edit that if you swapped the first image to footage that the intro text would lag during the transition.
     * We noticed that the syncToFrame was constantly trying to adjust the playback rate and resulting in weirdness. The solution we came up with was to set the trim data when
     * swapping from Image to Footage. I thought this had to do with timeRemap because of the code path we were taking, but it's possible it's not.
     * @TODO: Look into why this works and see if it's a problem deeper in.
     *
     * Note from Ryan: it looks like the layer in question is malformed due to a template construction issue.
     * The layer's in/out points are 99-147 and its parent comp's are 95-194. This means that the layer's
     * globally resolved in/out points are 194-194, giving us a duration of 0. This template probably
     * is only working out of sheer luck. The template should be fixed but this may also signal that we need
     * to be more defensive against in/out points that shouldn't be possible in the renderer.
     */
    footageLayer.setTrimData(footageLayer.getTrimData());

    return footageLayer;
  }

  /**
   * Replaces this layer with an image layer in the video descriptor, using the provided image asset.
   * Returns the new image layer.
   *
   * @param initialImageAsset - The image asset to use for the new image layer
   * @param applyImageLayerUpdates - An optional function to apply updates to the new image layer before adding it to the composition.
   *                                  This is necessary because things can get a little finicky when applying changes to a layer as it
   *                                  is being added to the composition and set up by the renderer; using this is your best bet to ensure your updates get applied.
   *                                  In an ideal world, this will hopefully be replaced by some sort of more robust system where we can batch video descriptor updates or something.
   */
  changeToImageLayer(
    initialImageAsset: ImageAsset,
    applyImageLayerUpdates?: (newImageLayer: ImageLayer) => void,
  ): ImageLayer {
    const imageLayer = ImageLayer.fromFootageLayer(this, initialImageAsset);
    if (applyImageLayerUpdates) {
      applyImageLayerUpdates(imageLayer);
    }
    this.parentComposition.replaceLayer(this, imageLayer);
    return imageLayer;
  }

  getDimensions() {
    return {
      width: this.rawLayerData.w,
      height: this.rawLayerData.h,
    };
  }

  /**
   * Get the footage asset associated with this layer.
   */
  getAsset() {
    const asset = this.videoDescriptor.assets.get(this.rawLayerData.refId);
    if (!(asset instanceof FootageAsset)) {
      throw new Error(`${this.toString()}: Asset ${this.rawLayerData.refId} is not a video`);
    }
    return asset;
  }

  /**
   * Get the URL of the footage asset associated with this layer.
   *
   * @param quality - The quality of the footage asset URL to get from the VPS.
   */
  getAssetURL(quality: FootageAssetQuality = FootageAssetQuality.medium) {
    return this.getAsset()?.getURL(quality);
  }

  /**
   * Get the thumbnail URLs of the footage asset associated with this layer.
   */
  getAssetThumbnailURLs() {
    return this.getAsset().getThumbnailURLs();
  }

  /**
   * Sets a new footage asset for this layer.
   * Resets all modifications on the layer to defaults.
   */
  setAsset(asset: FootageAsset) {
    // If we're changing the asset for a footage layer, we're going to make an opinionated decision
    // that all "coordinates" of cropping information (zoom level, cropX, cropY, etc.) should be reset.
    // However, we are going to maintain two cropping properties: contentFit and contentFitFillAlignment.
    // The theory here, is that `contentFit` is most influenced by the properties of the layer instead of the asset. `contentFitFillAlignment`
    // is specific to contentFit: 'fill'; so we're going to maintain that as well.
    const oldAsset = this.getAsset();

    // Ensure the asset is in the manifest
    this.videoDescriptor.addAsset(asset);
    this.updateRawLayerData({
      refId: asset.getID(),
      // Reset all modifications to defaults
      contentCropping: FootageLayer.DEFAULT_CROPPING,
      contentZoom: FootageLayer.DEFAULT_ZOOM,
      contentPadding: FootageLayer.DEFAULT_PADDING,
      contentBackgroundFill: FootageLayer.DEFAULT_BACKGROUND_FILL_COLOR,
      contentTrimStartTime: undefined,
      contentTrimDuration: undefined,
      contentPlaybackDuration: undefined,
    });
    this.dispatchEvent('change:asset', asset);
    this.dispatchEvent('change:contentModifications', undefined);

    // Clean up the old asset if it's no longer in use
    this.videoDescriptor.removeAsset(oldAsset);
  }

  /** ~~ Modifications ~~ */

  /** contentBackgroundFill modification */
  // Default color is transparent
  static DEFAULT_BACKGROUND_FILL_COLOR = '#FFFFFF00';

  getBackgroundFillColor(): string {
    return this.rawLayerData.contentBackgroundFill ?? FootageLayer.DEFAULT_BACKGROUND_FILL_COLOR;
  }
  setBackgroundFillColor(newColor: string) {
    this.updateRawLayerData({
      contentBackgroundFill: newColor,
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  /** contentCropping modification */
  static DEFAULT_CROPPING: ContentCropping = {
    x: 0,
    y: 0,
    width: 1,
    height: 1,
  };

  getCropping(): ContentCropping {
    return this.rawLayerData.contentCropping ?? FootageLayer.DEFAULT_CROPPING;
  }
  setCropping(newCroppingData: Partial<ContentCropping>) {
    if (this.getContentFitMode() !== AssetModificationFits.Fill) {
      throw new Error('Cannot set cropping on a layer with contentFit mode other than "fill"');
    }
    this.updateRawLayerData({
      contentCropping: {
        ...this.getCropping(),
        ...newCroppingData,
      },
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  /** contentFit modification */
  static DEFAULT_CONTENT_FIT_MODE: `${AssetModificationFits.Crop}` = 'crop';

  getContentFitMode(): `${AssetModificationFits}` {
    return this.rawLayerData.contentFit ?? FootageLayer.DEFAULT_CONTENT_FIT_MODE;
  }
  setContentFitMode(newFitMode: `${AssetModificationFits}`) {
    this.updateRawLayerData({
      contentFit: newFitMode,
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  /** contentFitFillAlignment modification */
  static DEFAULT_FIT_FILL_ALIGNMENT: `${FitFillAlignment.CenterCenter}` = 'CC';

  getFitFillAlignment(): `${FitFillAlignment}` {
    return this.rawLayerData.contentFitFillAlignment ?? FootageLayer.DEFAULT_FIT_FILL_ALIGNMENT;
  }
  setFitFillAlignment(newAlignment: `${FitFillAlignment}`) {
    this.updateRawLayerData({
      contentFitFillAlignment: newAlignment,
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  /** contentPadding modification */
  static DEFAULT_PADDING = 0;

  getPadding(): number {
    return this.rawLayerData.contentPadding ?? FootageLayer.DEFAULT_PADDING;
  }
  setPadding(newPadding: number) {
    this.updateRawLayerData({
      contentPadding: newPadding,
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  /** Trim modification */
  getTrimData(): FootageTrimData {
    return {
      trimStartTime: this.rawLayerData.contentTrimStartTime ?? 0,
      trimDuration: this.rawLayerData.contentTrimDuration ?? this.getLongestSecondsDuration(),
    };
  }

  setTrimData(
    trimDataOrGetter: FootageTrimData | ((currentTrimData: FootageTrimData) => FootageTrimData),
  ) {
    const trimData =
      typeof trimDataOrGetter === 'function'
        ? trimDataOrGetter(this.getTrimData())
        : trimDataOrGetter;

    // Keep values limited to 2 decimal places of precision
    const trimDuration = toPrecision(trimData.trimDuration, 2);
    const trimStartTime = toPrecision(trimData.trimStartTime, 2);

    const layerInPoint = this.rawLayerData.ip;

    const layerFrameDuration = this.getLongestFrameDuration();

    // Set time remapping keyframes on the layer to reflect the trim start/end times
    const timeRemappingKeyframes: ValueKeyframed = {
      a: 1,
      k: [
        {
          s: [trimStartTime],
          e: [trimStartTime + trimDuration],
          t: layerInPoint,
        },
        {
          t: layerInPoint + layerFrameDuration,
        },
      ],
    };

    this.updateRawLayerData({
      tm: timeRemappingKeyframes,
      contentTrimDuration: trimDuration,
      contentTrimStartTime: trimStartTime,
      // It's important to remember that while contentTrimDuration is in seconds, contentPlaybackDuration is in frames.
      // Not sure why we didn't just use frames across the board.
      contentPlaybackDuration: Math.min(
        // Use the trim duration if it's less than the layer's display duration,
        // but otherwise cap at the layer's display duration.
        trimDuration * this.videoDescriptor.getFramerate(),
        layerFrameDuration,
      ),
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  /** contentZoom modification */
  static DEFAULT_ZOOM: ContentZoom = {
    x: 0.5,
    y: 0.5,
    z: 1,
  };

  getZoom(): ContentZoom {
    return this.rawLayerData.contentZoom ?? FootageLayer.DEFAULT_ZOOM;
  }
  setZoom(newZoomData: Partial<ContentZoom>) {
    this.updateRawLayerData({
      contentZoom: {
        ...this.getZoom(),
        ...newZoomData,
      },
    });
    this.dispatchEvent('change:contentModifications', undefined);
  }

  /** isMuted modification */
  getIsMuted(): boolean {
    return this.rawLayerData.isMuted ?? false;
  }
  setIsMuted(isMuted: boolean) {
    this.updateRawLayerData({
      isMuted,
    });
    this.dispatchEvent('change:muted', isMuted);
  }

  /** masterVolume modification */
  static DEFAULT_MASTER_VOLUME = 1;

  getMasterVolume(): number {
    return this.rawLayerData.masterVolume ?? FootageLayer.DEFAULT_MASTER_VOLUME;
  }
  setMasterVolume(volume: number) {
    this.updateRawLayerData({
      masterVolume: volume,
    });
    this.dispatchEvent('change:masterVolume', volume);
  }

  cleanUp(): void {
    super.cleanUp();
    // Clean up this layer's asset
    this.videoDescriptor.removeAsset(this.getAsset());
  }
}
