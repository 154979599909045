import type { VideoDescriptor } from '../VideoDescriptor';
import type { Composition } from '../compositions';
import { SubCompositionLayer, VideoDescriptorLayer } from '../layers';
import { RootCompositionTimeline, SubCompositionTimeline } from './CompositionTimeline';

export class VideoDescriptorTimeline {
  videoDescriptor: VideoDescriptor;

  subCompositionTimelineLayerLookupMap = new Map<SubCompositionLayer, SubCompositionTimeline[]>();
  compositionTimelineLookupMap = new Map<
    Composition,
    Array<RootCompositionTimeline | SubCompositionTimeline>
  >();

  rootCompositionTimeline: RootCompositionTimeline;

  constructor(videoDescriptor: VideoDescriptor) {
    this.videoDescriptor = videoDescriptor;
    this.rootCompositionTimeline = this.createRootCompositionTimeline(
      videoDescriptor.getRootComposition(),
    );

    this.videoDescriptor.addEventListener('layerAdded', (event) => {
      const addedLayer = event.detail;
      if (!(addedLayer instanceof SubCompositionLayer)) {
        return;
      }

      if (this.subCompositionTimelineLayerLookupMap.has(addedLayer)) {
        // This layer has already been registered to the timeline, skip it
        return;
      }

      const parentCompositionTimelines = this.getParentCompositionTimelinesForLayer(addedLayer);
      if (parentCompositionTimelines.length === 0) {
        // This layer is not in any active composition, skip it
        console.error(
          `Could not find a parent composition timeline for new SubCompositionLayer ${addedLayer.toString()}. Skipping timeline registration.`,
        );
        return;
      }

      for (const parentCompositionTimeline of parentCompositionTimelines) {
        const subCompositionTimeline = this.createSubCompositionTimeline(
          addedLayer,
          parentCompositionTimeline,
        );
        const existingSubCompositionTimelines =
          this.subCompositionTimelineLayerLookupMap.get(addedLayer) ?? [];
        existingSubCompositionTimelines.push(subCompositionTimeline);
        this.subCompositionTimelineLayerLookupMap.set(addedLayer, existingSubCompositionTimelines);
      }
    });
    this.videoDescriptor.addEventListener('compositionRemoved', (event) => {
      const removedComposition = event.detail;
      // Delete all timelines associated with the removed composition
      this.compositionTimelineLookupMap.delete(removedComposition);
    });
  }

  getTimelinesForComposition(
    composition: Composition,
  ): Array<RootCompositionTimeline | SubCompositionTimeline> {
    return this.compositionTimelineLookupMap.get(composition) ?? [];
  }

  getParentCompositionTimelinesForLayer(
    layer: VideoDescriptorLayer,
  ): Array<RootCompositionTimeline | SubCompositionTimeline> {
    return this.getTimelinesForComposition(layer.parentComposition);
  }

  getVideoDescriptorInOutPoints(): {
    inPoint: number;
    outPoint: number;
  } {
    const rootCompositionTimeline = this.rootCompositionTimeline;
    return {
      inPoint: rootCompositionTimeline.inPoint,
      outPoint: rootCompositionTimeline.outPoint,
    };
  }

  createRootCompositionTimeline(rootComposition: Composition): RootCompositionTimeline {
    const timeline = new RootCompositionTimeline(rootComposition, this);
    this.compositionTimelineLookupMap.set(rootComposition, [timeline]);

    for (const layer of rootComposition.layers) {
      if (layer instanceof SubCompositionLayer) {
        const subCompositionTimeline = this.createSubCompositionTimeline(layer, timeline);
        const existingSubCompositionTimelines =
          this.subCompositionTimelineLayerLookupMap.get(layer) ?? [];
        existingSubCompositionTimelines.push(subCompositionTimeline);
        this.subCompositionTimelineLayerLookupMap.set(layer, existingSubCompositionTimelines);
      }
    }

    return timeline;
  }

  createSubCompositionTimeline(
    compositionInstanceLayer: SubCompositionLayer,
    parentCompositionTimeline: SubCompositionTimeline | RootCompositionTimeline,
  ): SubCompositionTimeline {
    const timeline = new SubCompositionTimeline(
      compositionInstanceLayer,
      parentCompositionTimeline,
    );
    const composition = compositionInstanceLayer.composition;

    const existingCompositionTimelines = this.compositionTimelineLookupMap.get(composition) ?? [];
    existingCompositionTimelines.push(timeline);
    this.compositionTimelineLookupMap.set(composition, existingCompositionTimelines);

    for (const layer of composition.layers) {
      if (layer instanceof SubCompositionLayer) {
        const subCompositionTimeline = this.createSubCompositionTimeline(layer, timeline);
        const existingSubCompositionTimelines =
          this.subCompositionTimelineLayerLookupMap.get(layer) ?? [];
        existingSubCompositionTimelines.push(subCompositionTimeline);
        this.subCompositionTimelineLayerLookupMap.set(layer, existingSubCompositionTimelines);
      }
    }

    return timeline;
  }

  /**
   * Gathers an array of global positions for every instance of a layer at a given frame number.
   */
  getGlobalLayerPositionsAtFrame(
    layer: VideoDescriptorLayer,
    frameNumber: number,
  ): { x: number; y: number; z: number }[] {
    const positions = new Array<{ x: number; y: number; z: number }>();

    const parentCompositionTimelines = this.getParentCompositionTimelinesForLayer(layer);
    for (const parentCompositionTimeline of parentCompositionTimelines) {
      const position = parentCompositionTimeline.getGlobalChildLayerPositionAtFrame(
        layer,
        frameNumber,
      );
      if (position) {
        positions.push(position);
      }
    }

    return positions;
  }

  /**
   * Gathers an array of global opacities for every active instance of a layer at a given frame number.
   */
  getGlobalLayerOpacitiesAtFrame(layer: VideoDescriptorLayer, frameNumber: number): number[] {
    const opacities = new Array<number>();

    const parentCompositionTimelines = this.getParentCompositionTimelinesForLayer(layer);
    for (const parentCompositionTimeline of parentCompositionTimelines) {
      const opacity = parentCompositionTimeline.getGlobalChildLayerOpacityAtFrame(
        layer,
        frameNumber,
      );

      if (opacity !== null) {
        opacities.push(opacity);
      }
    }

    return opacities;
  }

  /**
   * Gathers an array of all active layers at a given frame number.
   */
  getActiveLayersAtFrame(frameNumber: number): VideoDescriptorLayer[] {
    const activeLayers = new Set<VideoDescriptorLayer>();

    for (const compositionTimeline of this.compositionTimelineLookupMap.values()) {
      for (const timeline of compositionTimeline) {
        for (const layer of timeline.getActiveLayersAtGlobalFrame(frameNumber)) {
          activeLayers.add(layer);
        }
      }
    }

    return Array.from(activeLayers);
  }

  /**
   * Gathers an array of all visible layers at a given frame number.
   * We define visibility as a layer being active and having an opacity greater than 0.
   * This does not account for scenarios like a layer being active but hidden behind another layer.
   */
  getVisibleLayersAtFrame<TFilteredLayerType extends VideoDescriptorLayer>(
    frameNumber: number,
    filter?:
      | ((layer: VideoDescriptorLayer) => layer is TFilteredLayerType)
      | ((layer: VideoDescriptorLayer) => boolean),
  ): TFilteredLayerType[] {
    const visibleLayers = new Set<TFilteredLayerType>();

    for (const compositionTimeline of this.compositionTimelineLookupMap.values()) {
      for (const timeline of compositionTimeline) {
        for (const layer of timeline.getVisibleLayersAtGlobalFrame(frameNumber)) {
          if (!filter || filter(layer)) {
            visibleLayers.add(layer as TFilteredLayerType);
          }
        }
      }
    }

    return Array.from(visibleLayers);
  }

  getLayerGlobalInOutPointFrames(
    layer: VideoDescriptorLayer,
  ): { inPoint: number; outPoint: number }[] {
    const parentCompositionTimelines = this.getParentCompositionTimelinesForLayer(layer);
    return parentCompositionTimelines.map((parentCompositionTimeline) =>
      parentCompositionTimeline.getGlobalInOutPointFramesForLayer(layer),
    );
  }

  getAllLayerFrameDurations(layer: VideoDescriptorLayer): number[] {
    return this.getLayerGlobalInOutPointFrames(layer).map(
      ({ inPoint, outPoint }) => outPoint - inPoint,
    );
  }
}
