import { Effect } from './effects';
import { BooleanInt } from './helpers';
import { Mask } from './masks';
import {
  AssetModificationFits,
  ContentCropping,
  ContentZoom,
  FitFillAlignment,
  ImageAdjustments,
} from './modifications';
import { ShapeItem } from './shapes';
import { TextData } from './textAnimators';
import {
  MultiDimensionalValue,
  MultiDimensionalValueKeyframed,
  Value,
  ValueKeyframed,
} from './values';

export enum LayerType {
  Precomp = 0,
  Solid = 1,
  Image = 2,
  NullLayer = 3,
  Shape = 4,
  Text = 5,
  Audio = 6,
  PholderVideo = 7,
  ImageSeq = 8,
  Video = 9,
  PholderStill = 10,
  Guide = 11,
  Adjustment = 12,
  Camera = 13,
  Light = 14,
  Data = 15,
  WaymarkVideo = 100,
  WaymarkAudio = 101,
}

export enum LayerStyleType {
  Stroke = 0,
}

export interface BaseLayerStyle {
  /** Layer style type */
  ty: LayerStyleType;
  /** Layer style name */
  nm: string;
}

export interface StrokeLayerStyle extends BaseLayerStyle {
  /** "frameFX/color" */
  c: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** "frameFX/size" */
  s: Value | ValueKeyframed;
}

export type LayerStyle = StrokeLayerStyle;

export type BaseTransform = {
  /** Transform Anchor Point */
  a?: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Transform Scale */
  s?: MultiDimensionalValue | MultiDimensionalValueKeyframed;
  /** Transform Opacity */
  o?: Value | ValueKeyframed;
  /**
   * 1 = The layer's auto orient is set to AutoOrientType.ALONG_PATH
   * 0 = The layer's auto orient is *not* set to AutoOrientType.ALONG_PATH
   */
  ao?: BooleanInt;
};

export interface TransformPosition {
  /** Transform Position */
  p: MultiDimensionalValue | MultiDimensionalValueKeyframed;
}

export interface TransformSeperatedDimensionsPosition {
  p: {
    s: true;
    /** Transform Position X ("ADBE Position_0") */
    x: Value | ValueKeyframed;
    /** Transform Position Y ("ADBE Position_1") */
    y: Value | ValueKeyframed;
    /**
     * Transform Position Z ("ADBE Position_2")
     *
     * Only if the layer is 3D.
     * */
    z?: Value | ValueKeyframed;
  };
}

export interface TransformRotation {
  /** Transform Rotation */
  r: Value | ValueKeyframed;
}

export interface TransformSeperatedDimensionsRotation {
  /** "ADBE Rotate X" */
  rx: Value | ValueKeyframed;
  /** "ADBE Rotate Y" */
  ry: Value | ValueKeyframed;
  /** "ADBE Rotate Z" */
  rz: Value | ValueKeyframed;
  /** Transform orientation */
  or: MultiDimensionalValue | MultiDimensionalValueKeyframed;
}

export type LayerTransform = BaseTransform &
  (TransformPosition | TransformSeperatedDimensionsPosition) &
  (TransformRotation | TransformSeperatedDimensionsRotation);

export type CameraLayerTransform = {
  /** 'ADBE Anchor Point' */
  a: LayerTransform['a'];
  p: LayerTransform['p'];
} & TransformSeperatedDimensionsRotation;

enum SupportedImportantChange {
  TextFillColor = 'TEXT_FILL_COLOR.color',
  TextStrokeColor = 'TEXT_STROKE_COLOR.color',
  EffectFillColor = 'EFFECT_FILL_COLOR.color',
}

export enum TextResizingStrategy {
  Default = 'default',
  StepAndBreakWords = 'stepAndBreakWords',
}
type TextResizingStrategyString = `${TextResizingStrategy}`;

export enum TextResizingStrategyStepDirection {
  Up = 'up',
  Down = 'down',
}
type TextResizingStrategyStepDirectionString = `${TextResizingStrategyStepDirection}`;

export enum TextVerticalAlignment {
  Top = 'top',
  Center = 'center',
  Bottom = 'bottom',
  /**
   * @migrationtodo
   * TODO: 'middle' and 'center' are equivalent. 'middle' is deprecated so we
   * should migrate it to 'center' in the future.
   */
  DeprecatedMiddle = 'middle',
}

export enum TrackMatte {
  Alpha = 1,
  AlphaInverted = 2,
  Luma = 3,
  LumaInverted = 4,
}

export interface LayerTextOptions {
  resizingStrategy?:
    | null
    | TextResizingStrategyString
    | [
        TextResizingStrategyString,
        {
          stepDirection?:
            | [TextResizingStrategyStepDirectionString]
            | [TextResizingStrategyStepDirectionString, TextResizingStrategyStepDirectionString];
        },
      ];
  verticalAlignment?: `${TextVerticalAlignment}`;
}

export enum BlendMode {
  Normal = 0,
  Multiply = 1,
  Screen = 2,
  Overlay = 3,
  Darken = 4,
  Lighten = 5,
  ColorDodge = 6,
  ColorBurn = 7,
  HardLight = 8,
  SoftLight = 9,
  Difference = 10,
  Exclusion = 11,
  Hue = 12,
  Saturation = 13,
  Color = 14,
  Luminosity = 15,
  Add = 16,
  HardMix = 17,
}

export interface BaseLayer {
  /** Does this layer have any masks? */
  hasMask?: boolean;
  /** Type of layer. */
  ty: LayerType;
  /** Transform properties */
  ks: LayerTransform;
  /** Auto-Orient along path AE property. */
  ao: BooleanInt;
  /** Blend Mode */
  bm: BlendMode;
  /** 3d layer flag */
  ddd: BooleanInt;
  /**
   * Is this a hidden layer? If false or undefined, the layer is visible. */
  hd?: boolean;
  /** Is this layer being used as a track matte? */
  td?: BooleanInt;
  /** Layer index in AE. Used for parenting and expressions. */
  ind: number;
  /** In Point of layer. Sets the initial frame of the layer. */
  ip: number;
  /** Out Point of layer. Sets the final frame of the layer. */
  op: number;
  /** Start Time of layer. Sets the start time of the layer. */
  st: number;
  /** After Effects Layer Name. Used for expressions. */
  nm: string;
  /** List of Masks */
  masksProperties?: Mask[];
  /** List of Effects */
  ef?: Effect[];
  /** Layer Time Stretching */
  sr: number;
  /** Layer Parent. Uses ind of parent. */
  parent?: number;
  /** Layer has motion blur applied? */
  hasMotionBlur: boolean;
  /** Layer has collapse transformation applied? */
  hasCollapseTransformation: boolean;
  /** Track Matte Type */
  tt?: TrackMatte;
  /**
   * Layer Styles
   */
  sy?: LayerStyle[];
  /**
   * Unknown property.
   *
   * If you're interested in investigating what the heck tg, ln, and cl are
   * please refer to the following file:
   * https://github.com/stikdev/bodymovin-extension-private/blob/5109465daf3d5fc902d66267b4159f9676f1029d/bundle/jsx/elements/layerElement.jsx#L77-L78
   *
   * They don't appear to be used. May the odds be ever in your favor.
   * */
  tg?: string;
  /**
   * Parsed layer name originally used as html class in bodymovin's SVG/HTML renderer
   *
   * If you're interested in investigating what the heck tg, ln, and cl are
   * please refer to the following file:
   * https://github.com/stikdev/bodymovin-extension-private/blob/5109465daf3d5fc902d66267b4159f9676f1029d/bundle/jsx/elements/layerElement.jsx#L77-L78
   *
   * They don't appear to be used. May the odds be ever in your favor.
   * */
  ln?: string;
  /**
   * Parsed layer name used as html id on SVG/HTML renderer.
   *
   * If you're interested in investigating what the heck tg, ln, and cl are
   * please refer to the following file:
   * https://github.com/stikdev/bodymovin-extension-private/blob/5109465daf3d5fc902d66267b4159f9676f1029d/bundle/jsx/elements/layerElement.jsx#L77-L78
   *
   * They don't appear to be used. May the odds be ever in your favor.
   * */
  cl?: string;
  /** Waymark metadata for the layer */
  meta: {
    /** Waymark UUID for the Layer */
    uuid: string;
    textOptions?: null | LayerTextOptions;
    id?: string;
  };
  /**
   * Map of references that are ignored by the layer in favor of a custom one-off value. An "override" if you will.
   */
  ignoredReferences?: {
    textContent?: boolean;
    imageContent?: boolean;
    font?: boolean;
    fillColor?: boolean;
    strokeColor?: boolean;
    fillEffect?: boolean;
    gradientFill?: boolean;
  };
}

/**
 * The AVLayer object provides an interface to those layers that contain AVItem objects;
 * composition layers, footage layers, solid layers, text layers, and sound layers.
 */
export interface AVLayer extends BaseLayer {
  /** Layer's Time remapping (if enabled) */
  tm?: Value | ValueKeyframed;
}

export interface PreCompLayer extends AVLayer {
  ty: LayerType.Precomp;
  /** id pointing to the source composition defined on 'assets' object */
  refId: string;
  /** The width of the layer */
  w: number;
  /** The height of the layer */
  h: number;
}

export interface SolidLayer extends AVLayer {
  ty: LayerType.Solid;
  /** Color of the solid in hex */
  sc: string;
  /** Height of the solid. */
  sh: number;
  /** Width of the solid. */
  sw: number;
}

// These map to CSS easing function presets
export enum MotionEffectEase {
  Linear = 'linear',
  EaseIn = 'easeIn',
  EaseOut = 'easeOut',
  EaseInOut = 'easeInOut',
  Ease = 'ease',
}

export interface ContentZoomMotionEffect {
  from: ContentZoom;
  to: ContentZoom;
  ease: `${MotionEffectEase}`;
}

export type ImageLayer = BaseLayer & {
  ty: LayerType.Image;
  /** id pointing to the source image defined on 'assets' object */
  refId: string;
  contentAdjustments?: ImageAdjustments;
  contentBackgroundFill?: string;
  contentCropping?: ContentCropping;
  contentFillColor?: string; // #ffffff00
  contentFit?: `${AssetModificationFits}`;
  contentFitFillAlignment?: `${FitFillAlignment}`;
  contentPadding?: number; // 0 to 500
  contentZoom?: ContentZoom | ContentZoomMotionEffect;
  // @migrationtodo: is this optional?
  h?: number;
  w?: number;
};
export interface NullLayer extends BaseLayer {
  ty: LayerType.NullLayer;
}

export interface ShapeLayer extends BaseLayer {
  ty: LayerType.Shape;
  /** Shape list of items */
  shapes: ShapeItem[];
}

export interface TextLayer extends AVLayer {
  ty: LayerType.Text;
  /** Text Data */
  t: TextData;
  /** id pointing to a bitmap font asset defined in 'assets' array */
  refId?: string;
}

export interface AudioLayer extends AVLayer {
  ty: LayerType.Audio;
  refId?: string | null;
}
export interface PholderVideoLayer extends AVLayer {
  ty: LayerType.PholderVideo;
}
export interface ImageSeqLayer extends BaseLayer {
  ty: LayerType.ImageSeq;
}
export interface VideoLayer extends AVLayer {
  ty: LayerType.Video;
  h?: number;
  w?: number;
  refId?: string | null;
}
export interface PholderStillLayer extends BaseLayer {
  ty: LayerType.PholderStill;
}
export interface GuideLayer extends BaseLayer {
  ty: LayerType.Guide;
}
export interface AdjustmentLayer extends BaseLayer {
  ty: LayerType.Adjustment;
}
// TODO: We may want to not do the Omit here and simply specify what this has vs. other layers
// See where there is a pretty big fork in logic here:
// https://github.com/stikdev/bodymovin-extension-private/blob/5109465daf3d5fc902d66267b4159f9676f1029d/bundle/jsx/elements/layerElement.jsx#L245
export interface CameraLayer
  extends Omit<BaseLayer, 'ao' | 'hasMotionBlur' | 'hasCollapseTransformation' | 'ks' | 'tm'> {
  ty: LayerType.Camera;
  /** "ADBE Camera Zoom" */
  pe: Value | ValueKeyframed;
  /** Transform */
  ks: CameraLayerTransform;
}
export interface LightLayer extends BaseLayer {
  ty: LayerType.Light;
}
export interface DataLayer extends BaseLayer {
  ty: LayerType.Data;
}
export interface WaymarkVideoLayer extends AVLayer {
  ty: LayerType.WaymarkVideo;
  w: number;
  h: number;
  isMuted?: boolean;
  masterVolume?: number;
  refId: string;
  contentTrimStartTime?: number;
  /**
   * Length of time to trim the layer's footage asset to which will be stretched to fit within the contentPlaybackDuration.
   * This is in seconds, for some reason.
   */
  contentTrimDuration?: number;
  /**
   * Length of time that the layer's content will play for, in frames.
   */
  contentPlaybackDuration?: number;
  contentBackgroundFill?: string;
  contentCropping?: ContentCropping;
  contentPadding?: number;
  contentFit?: `${AssetModificationFits}`;
  contentZoom?: ContentZoom;
  contentFitFillAlignment?: `${FitFillAlignment}`;
  /** This property has been removed */
  modifications?: never;
}

export type VolumeChange = {
  type: 'targetDucking';
  /** This source's volume will change based on a ducking target's
   * in point and out point */
  duckingTarget: BaseLayer['meta']['uuid'];
  /** When the ducking target is active, this source's volume will be changed
   * to the targetVolue (betwen 0 and 1) */
  targetVolume: number;
};

export interface WaymarkAudioLayer extends Omit<AVLayer, 'nm'> {
  ty: LayerType.WaymarkAudio;
  isMuted?: boolean;
  masterVolume?: number;
  volumeChanges?: VolumeChange[];
  /**
   * TODO: we create auxilliary audio layers ourselves so nm is not always present
   *
   * @migrationtodo
   * */
  nm?: string;
  refId: string;
  volume?: Value | ValueKeyframed;
}

export type Layer =
  | AudioLayer
  | PholderVideoLayer
  | ImageSeqLayer
  | VideoLayer
  | PholderStillLayer
  | GuideLayer
  | AdjustmentLayer
  | CameraLayer
  | LightLayer
  | DataLayer
  | WaymarkVideoLayer
  | WaymarkAudioLayer
  | ImageLayer
  | NullLayer
  | PreCompLayer
  | ShapeLayer
  | SolidLayer
  | TextLayer;
