import { TextLayer, ImageLayer, FootageLayer } from '../../layers';
import { ImageReference, TextReference } from '../../references';
import type { VideoDescriptor } from '../../VideoDescriptor';

/**
 * Util to get a combined list of editable "form field content", meaning text, image, and footage layers and switches.
 * This maps to what fields should be displayed in the editor form, but is also meaningful in the autofill service.
 */
export const getVideoDescriptorEditableFormFieldLayersAndSwitches = (
  videoDescriptor: VideoDescriptor,
) => {
  const switches = videoDescriptor.switches;
  const switchLayerSet = new Set(
    // Get a flat list of all layers in all options in all switches whose content can be edited.
    switches.flatMap((sceneSwitch) =>
      sceneSwitch.options.flatMap((option) =>
        option.layers.filter((layer) => layer.getCanEditFieldType('content')),
      ),
    ),
  );

  const references = new Set<TextReference | ImageReference>();

  const nonSwitchFormLayers = videoDescriptor.filterLayers(
    (layer): layer is TextLayer | ImageLayer | FootageLayer => {
      // Exclude layers which belong to switches; we just want to include the Switch at the top level and
      // you can get the layers that belong to that switch from the Switch object.
      if (switchLayerSet.has(layer)) {
        return false;
      }

      // Exclude any layers whose content isn't editable
      if (!layer.getCanEditFieldType('content')) {
        return false;
      }

      if (layer.textContentReference) {
        references.add(layer.textContentReference);
        return false;
      }

      if (layer.imageContentReference) {
        references.add(layer.imageContentReference);
        return false;
      }

      return (
        layer instanceof TextLayer || layer instanceof ImageLayer || layer instanceof FootageLayer
      );
    },
  );

  const videoDescriptorTimeline = videoDescriptor.timeline;

  const layerTopLeftSqDistCache = new Map<(typeof nonSwitchFormLayers)[number], number | null>();

  const videoDescriptorDimensions = videoDescriptor.getDimensions();

  /**
   * Get the smallest square distance from the top-left corner of the video for a given layer at a given frame.
   * This square distance is in normalized 0-1 unit coordinates to hopefully better reflect how the layer's position
   * _feels_ in the video when reading from top-left to bottom-right.
   *
   * We're using square distance because it's cheaper to calculate than the actual distance but can still be used
   * for comparison purposes.
   */
  const getLayerTopLeftSqDist = (layer: (typeof nonSwitchFormLayers)[number], frame: number) => {
    let bestSqDist = layerTopLeftSqDistCache.get(layer) ?? null;
    if (bestSqDist) {
      return bestSqDist;
    }

    const positions = videoDescriptorTimeline.getGlobalLayerPositionsAtFrame(layer, frame);
    for (const pos of positions) {
      // Translate position coordinates to be in the range [0, 1] where (0, 0)
      // and then calculate those coordinates' square distance from the top-left corner.
      const sqDist =
        Math.pow(pos.x / videoDescriptorDimensions.width, 2) +
        Math.pow(pos.y / videoDescriptorDimensions.height, 2);
      if (bestSqDist === null || sqDist < bestSqDist) {
        bestSqDist = sqDist;
      }
    }
    layerTopLeftSqDistCache.set(layer, bestSqDist);

    return bestSqDist;
  };

  const combinedLayersAndSwitches = [...switches, ...references, ...nonSwitchFormLayers].sort(
    (contentA, contentB) => {
      const frameA = contentA.getIdealDisplayFrame();
      const frameB = contentB.getIdealDisplayFrame();

      if (frameA !== frameB) {
        return frameA - frameB;
      }

      if ('rawLayerData' in contentA && 'rawLayerData' in contentB) {
        // If both pieces of content are layers, we can try to use position as a tiebreaker.
        // Specifically, we'll use the square of the distance from the top-left corner of the video.
        // (leaving it squared because sqrt is expensive and we don't need it for this rough comparison)
        const aTopLeftSqDist = getLayerTopLeftSqDist(contentA, frameA);
        const bTopLeftSqDist = getLayerTopLeftSqDist(contentB, frameB);

        if (aTopLeftSqDist !== null && bTopLeftSqDist !== null) {
          return aTopLeftSqDist - bTopLeftSqDist;
        }
      }

      return 0;
    },
  );

  return combinedLayersAndSwitches;
};
